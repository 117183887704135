// app/javascript/controllers/offered_dishes_controller.js

import { Controller } from "stimulus"

export default class extends Controller {
  updateLimitBadge(limit) {
    let limitBadge = this.element.querySelector(`span.badge.bg-primary[data-dish-id="${this.element.dataset.id}"]`);
    limitBadge.textContent = `max ${limit}`;
  }

  updateAutoButtonAndBadge(data, element) {
    element.textContent = data.auto ? 'Отмена дежурного блюда' : 'Сделать дежурным';
    element.dataset.auto = data.auto;

    let autoBadge = this.element.querySelector(`span.badge.bg-success[data-dish-id="${this.element.dataset.id}"]`);
    if(autoBadge) {
      if (data.auto) {
        autoBadge.textContent = 'Дежурное';
      } else {
        autoBadge.remove();
      }
    } else if(data.auto) {
      let badge = document.createElement("span");
      badge.className = "badge bg-success me-1";
      badge.dataset.dishId = this.element.dataset.id;
      badge.textContent = "Дежурное";

      let badgesDiv = this.element.querySelector('.badges');
      badgesDiv.prepend(badge);
    }
  }

  updateField(e) {
    e.preventDefault()

    let fieldName = e.target.getAttribute('name') || 'auto';
    let fieldValue = e.target.getAttribute('name') ? e.target.value : !(e.target.dataset.auto === 'true');
    // csrf token is set to empty string for test environment requests
    const csrfToken = document.querySelector('[name=csrf-token]') ? document.querySelector('[name=csrf-token]').content : '';
    const utilityController = this.application.getControllerForElementAndIdentifier(this.element, "utility");
    
    utilityController.sendRequest(
      `/admin/offered_dishes/${this.element.dataset.id}`,
      'PATCH',
      { offered_dish: { [fieldName]: fieldValue } },
      csrfToken
    )
    .then(response => response.json())
    .then(data => {
      if (data.status === 'success') {
        utilityController.revealFlashMessage(data.message)
        switch(fieldName) {
          case 'limit':
            this.updateLimitBadge(data.offered_dish.limit);
            break;
          case 'auto':
            this.updateAutoButtonAndBadge(data.offered_dish, e.target);
            break;
        }
      } else {
        utilityController.revealFlashMessage(data.message)
      }
    })
    .catch((error) => {
      console.error('Error:', error);
    });
  }
}
