import IMask from "imask";

document.addEventListener("turbo:load", () => {
  const phones = document.querySelectorAll("[data-phone]");
  const phoneMask = "+{7} (000) 000-00-00";
  
  [].forEach.call(phones, (phone) => {
    const maskOptions = {
      mask: phoneMask,
      lazy: false,
    };
    const mask = new IMask(phone, maskOptions);
  });
});
