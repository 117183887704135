import flatpickr from "flatpickr";

document.addEventListener("turbo:load", () => {
  const Russian = require("flatpickr/dist/l10n/ru.js").default.ru;

  flatpickr("[data-behavior='flatpickr-time-alt']", {
    enableTime: true,
    "locale": Russian,
    disableMobile: true,
    enableTime: true,
    dateFormat: 'Z',
    altInput: true,
    altFormat: 'Y-m-d H:i'
  })
})
