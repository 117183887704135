$(document).on("turbo:load", () => {
  $(".offered-dish-in-modal-title").on("click", (e) => {
    e.preventDefault();
    $(e.target).closest('.offered-dish-in-modal').children('.offered-dish-in-modal-times').toggle();
  });

  $('.transaction-description a').on("click", (e) => {
    // e.preventDefault();
    $(e.target).closest('.offered-dish').children('.dish-description').toggle();
  });

  
  $('.btn-block-on-click').on("click", (e) => {
    e.preventDefault();
    $(e.target).addClass('disabled')
  });
});