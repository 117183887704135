$(document).on("turbo:load", () => {
  analyzeKeys($('.sign_in_usercode')[0]);
  $('.sign_in_usercode').on('keyup', (e) => {
    analyzeKeys(e.target);
  });
});

function analyzeKeys(target) {
  const rusLower = "абвгдеёжзийклмнопрстуфхцчшщъыьэюя";
  const rusUpper = rusLower.toUpperCase();
  const numbers = "0123456789";
  const symbols = "-";
  const allowedKeys = rusLower + rusUpper + numbers + symbols;

  const keys = target.value.split('');
  const filteredKeys = keys.filter((key) => {
    return !allowedKeys.includes(key);
  });

  if (filteredKeys.length > 0) {
    $("#not-allowed-keys").removeClass("hidden");
    $(".sign_in_submit").addClass("hidden");
  } else {
    $("#not-allowed-keys").addClass("hidden");
    $(".sign_in_submit").removeClass("hidden");
  }
}