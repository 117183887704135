// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

import Rails from "@rails/ujs"
import "@hotwired/turbo-rails"
import * as ActiveStorage from "@rails/activestorage"
import "jquery";
import "popper.js";
import "@popperjs/core";
import 'bootstrap';
import "channels"
import '../src/scss/application.scss';
// import '../src/scss/print.scss';
import "../src/images";
import "@fortawesome/fontawesome-free/js/all.js";
import "@fortawesome/fontawesome-free/css/all.css";
import "../src/js/scroll";
import "../src/js/flatpickr_date.js";
import "../src/js/flatpickr_time.js";
import "../src/js/flatpickr_time_alt.js";
import "../src/js/flatpickr_reports.js";
import "../src/js/global_search";
import "../src/js/menus.js";
import "../src/js/transactions.js";
import "../src/js/time_helper";
import "../src/js/imask";
import "../src/js/sign_in";
import "../src/js/bridge";
import "../src/js/limit_input";

window.jQuery = $;
window.$ = $;

Rails.start()
ActiveStorage.start()

import "controllers"
