// app/javascript/your/custom/path/timeHelper.js
// Don't forget to import into your pack

import formatDistance from "date-fns/formatDistance";
import format from "date-fns/format";
import { utcToZonedTime } from 'date-fns-tz';

document.addEventListener("turbo:load", function() {
  const timeHelpers = document.querySelectorAll("[data-time-format]");

  Array.from(timeHelpers).forEach(function(timeHelper) {


    const timeFormat = timeHelper.getAttribute("data-time-format");
    const value = timeHelper.getAttribute("data-time-value");
    const datetime = Date.parse(JSON.parse(value));

    if (timeFormat === "relative") {
      timeHelper.textContent = formatDistance(datetime, new Date());
    }
    else {
      const timeZone = '+05:00';
      const zonedDatetime = utcToZonedTime(datetime, timeZone);
      timeHelper.textContent =  format(zonedDatetime, timeFormat);
    }
  });
});