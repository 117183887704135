import NProgress from "nprogress";

$(document).on("turbo:load", () => {
  $('#global-search').on('click', (e) => {
    e.preventDefault();
    $('#search-frame').fadeIn()
    $('#search-frame-input').focus();
  });

  $('#search-frame-close').on('click', (e) => {
    e.preventDefault();
    $('#search-frame').fadeOut()
    $('#search-frame-input').val('')
    clearAll()
  });

  $('#search-frame-input').on('keyup', (e) => {
    e.preventDefault();
    if (e.target.value.length > 2)
      query(e.target.value);
    else
      clearAll();
  });
});

const query = (query) => {
  $.ajax({
    url: '/admin/search',
    data: { 'q': query },
    dataType: 'json',
    beforeSend: () => NProgress.start(),
    success: (data) => {
      console.log(data);
      NProgress.done()
      clearAll()
      const users = data[0]
      const menus = data[1]
      const dishes = data[2]

      if (users !== null && users.length > 0) {
        $('<h2>Пользователи</h2>').appendTo('#search-users')
        insertUsers(users)
      } 

      if (menus !== null && menus.length > 0) {
        $('<h2>Меню</h2>').appendTo('#search-menus')
        insertMenus(menus)
      }

      if (dishes !== null && dishes.length > 0) {
        $('<h2>Блюда</h2>').appendTo('#search-dishes')
        insertDishes(dishes)
      }
    }
  })
}

const insertUsers = (users) => {
  users.forEach(user => {
    const el = element('users', user.id, user.last_name + ' ' + user.first_name, user.phone, user.email);
    $(el).appendTo('#search-users');
  })
}

const insertMenus = (menus) => {
  menus.forEach(menu => {
    const el = element('menus', menu.id, menu.date, menu.canteen.title, menu.user_group.title)
    $(el).appendTo('#search-menus')
  });
}

const insertDishes = (dishes) => {
  dishes.forEach(dish => {
    console.log(dish);
    const el = element('dishes', dish.id, dish.title, dish.description.slice(0, 25) + '..', dish.price + ' ₸', dish.image.s.url)
    $(el).appendTo('#search-dishes')
  });
}
  

const element = (model, id, header, data1, data2, img) => {
  return('<div class="col-md-3 search-element">\
            <a class="link-unstyled" href="/admin/' + model + '/' + id + '">\
              <img class="search-element-img" src="' + img  + '"></img>\
              <div class="search-element-header">' + header + '</div>\
              <div class="search-element-aside">' + data1 + '</div>\
              <div class="search-element-aside">' + data2 + '</div>\
            </a>\
          </div>')
}

const clearAll = () => {
  $('#search-users').html('')
  $('#search-menus').html('')
  $('#search-dishes').html('')
}
