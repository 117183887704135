import flatpickr from "flatpickr";
import "flatpickr/dist/flatpickr.min.css";

document.addEventListener("turbo:load", () => {
  const Russian = require("flatpickr/dist/l10n/ru.js").default.ru;

  flatpickr("[data-behavior='flatpickr-reports']", {
    dateFormat: 'Y-m-d',
    "locale": Russian,
    maxDate: 'today'
  })
})