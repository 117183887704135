document.addEventListener("turbo:load", () => {
  const scrollToY = localStorage.getItem("scrollToY") || 0;
  window.scrollTo(0, scrollToY);
  localStorage.setItem("scrollToY", 0);

  const links = document.querySelectorAll("a[data-scroll=\"preserve\"]");
  [].forEach.call(links, (link) => {
    link.addEventListener("click", () => {
      localStorage.setItem("scrollToY", document.documentElement.scrollTop);
    });
  });
});
