import "./users-128.png";
import "./user-groups-128.png";
import "./reports-128.png";
import "./my-transactions-128.png";
import "./menus-128.png";
import "./menu-128.png";
import "./logo.png";
import "./dishes-128.png";
import "./change-password-128.png";
import "./canteen-128.png";
import "./empty-dish.png";