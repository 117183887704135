// app/javascript/controllers/utility_controller.js

import { Controller } from "stimulus"

export default class extends Controller {
  sendRequest(url, method, data, csrfToken) {
    return fetch(url, {
      method: method,
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'X-CSRF-Token': csrfToken,
      },
      body: JSON.stringify(data)
    });
  }
  
  toggleAddons(e) {
    let shouldReveal = e.type === 'mouseover';
    this.element.querySelectorAll('.addons').forEach((element) => {
      element.classList.toggle('hidden', !shouldReveal);
    })
  }

  revealFlashMessage(message) {
    const flashMessage = document.createElement("div");
    flashMessage.className = "mobile-flash animate__animated animate__fadeInDown";
    flashMessage.innerHTML = `<div class="mobile-flash__body">${message}</div>`;
    document.body.prepend(flashMessage);
    // Automatically remove the flash message after 1 second
    setTimeout(function() {
      flashMessage.classList.add('animate__fadeOut');
      // Optionally remove the element from the DOM after the fade-out animation is done
      setTimeout(function() {
        flashMessage.remove();
      }, 3000);
    }, 3000);
  }
}